// cityCode.js

export const cityCodeList = [{
  "name": "安徽",
  "adcode": "340000",
  "city": [{
    "adcode": "340100",
    "name": "合肥"
  }, {
    "adcode": "340200",
    "name": "芜湖"
  }, {
    "adcode": "340300",
    "name": "蚌埠"
  }, {
    "adcode": "340400",
    "name": "淮南"
  }, {
    "adcode": "340500",
    "name": "马鞍山"
  }, {
    "adcode": "340600",
    "name": "淮北"
  }, {
    "adcode": "340700",
    "name": "铜陵"
  }, {
    "adcode": "340800",
    "name": "安庆"
  }, {
    "adcode": "341000",
    "name": "黄山"
  }, {
    "adcode": "341100",
    "name": "滁州"
  }, {
    "adcode": "341200",
    "name": "阜阳"
  }, {
    "adcode": "341300",
    "name": "宿州"
  }, {
    "adcode": "341500",
    "name": "六安"
  }, {
    "adcode": "341600",
    "name": "亳州"
  }, {
    "adcode": "341700",
    "name": "池州"
  }, {
    "adcode": "341800",
    "name": "宣城"
  }]
}, {
  "name": "福建",
  "adcode": "350000",
  "city": [{
    "adcode": "350100",
    "name": "福州"
  }, {
    "adcode": "350200",
    "name": "厦门"
  }, {
    "adcode": "350300",
    "name": "莆田"
  }, {
    "adcode": "350400",
    "name": "三明"
  }, {
    "adcode": "350500",
    "name": "泉州"
  }, {
    "adcode": "350600",
    "name": "漳州"
  }, {
    "adcode": "350700",
    "name": "南平"
  }, {
    "adcode": "350800",
    "name": "龙岩"
  }, {
    "adcode": "350900",
    "name": "宁德"
  }]
}, {
  "name": "广东",
  "adcode": "440000",
  "city": [{
    "adcode": "440100",
    "name": "广州"
  }, {
    "adcode": "440200",
    "name": "韶关"
  }, {
    "adcode": "440300",
    "name": "深圳"
  }, {
    "adcode": "440400",
    "name": "珠海"
  }, {
    "adcode": "440500",
    "name": "汕头"
  }, {
    "adcode": "440600",
    "name": "佛山"
  }, {
    "adcode": "440700",
    "name": "江门"
  }, {
    "adcode": "440800",
    "name": "湛江"
  }, {
    "adcode": "440900",
    "name": "茂名"
  }, {
    "adcode": "441200",
    "name": "肇庆"
  }, {
    "adcode": "441300",
    "name": "惠州"
  }, {
    "adcode": "441400",
    "name": "梅州"
  }, {
    "adcode": "441500",
    "name": "汕尾"
  }, {
    "adcode": "441600",
    "name": "河源"
  }, {
    "adcode": "441700",
    "name": "阳江"
  }, {
    "adcode": "441800",
    "name": "清远"
  }, {
    "adcode": "441900",
    "name": "东莞"
  }, {
    "adcode": "442000",
    "name": "中山"
  }, {
    "adcode": "445100",
    "name": "潮州"
  }, {
    "adcode": "445200",
    "name": "揭阳"
  }, {
    "adcode": "445300",
    "name": "云浮"
  }]
}, {
  "name": "广西",
  "adcode": "450000",
  "city": [{
    "adcode": "450100",
    "name": "南宁"
  }, {
    "adcode": "450200",
    "name": "柳州"
  }, {
    "adcode": "450300",
    "name": "桂林"
  }, {
    "adcode": "450400",
    "name": "梧州"
  }, {
    "adcode": "450500",
    "name": "北海"
  }, {
    "adcode": "450600",
    "name": "防城港"
  }, {
    "adcode": "450700",
    "name": "钦州"
  }, {
    "adcode": "450800",
    "name": "贵港"
  }, {
    "adcode": "450900",
    "name": "玉林"
  }, {
    "adcode": "451000",
    "name": "百色"
  }, {
    "adcode": "451100",
    "name": "贺州"
  }, {
    "adcode": "451200",
    "name": "河池"
  }, {
    "adcode": "451300",
    "name": "来宾"
  }, {
    "adcode": "451400",
    "name": "崇左"
  }]
}, {
  "name": "贵州",
  "adcode": "520000",
  "city": [{
    "adcode": "520100",
    "name": "贵阳"
  }, {
    "adcode": "520200",
    "name": "六盘水"
  }, {
    "adcode": "520300",
    "name": "遵义"
  }, {
    "adcode": "520400",
    "name": "安顺"
  }, {
    "adcode": "520500",
    "name": "毕节"
  }, {
    "adcode": "520600",
    "name": "铜仁"
  }, {
    "adcode": "522300",
    "name": "黔西南布依族苗族自治州"
  }, {
    "adcode": "522600",
    "name": "黔东南苗族侗族自治州"
  }, {
    "adcode": "522700",
    "name": "黔南布依族苗族自治州"
  }]
}, {
  "name": "甘肃",
  "adcode": "620000",
  "city": [{
    "adcode": "620100",
    "name": "兰州"
  }, {
    "adcode": "620200",
    "name": "嘉峪关"
  }, {
    "adcode": "620300",
    "name": "金昌"
  }, {
    "adcode": "620400",
    "name": "白银"
  }, {
    "adcode": "620500",
    "name": "天水"
  }, {
    "adcode": "620600",
    "name": "武威"
  }, {
    "adcode": "620700",
    "name": "张掖"
  }, {
    "adcode": "620800",
    "name": "平凉"
  }, {
    "adcode": "620900",
    "name": "酒泉"
  }, {
    "adcode": "621000",
    "name": "庆阳"
  }, {
    "adcode": "621100",
    "name": "定西"
  }, {
    "adcode": "621200",
    "name": "陇南"
  }, {
    "adcode": "622900",
    "name": "临夏回族自治州"
  }, {
    "adcode": "623000",
    "name": "甘南藏族自治州"
  }]
}, {
  "name": "河北",
  "adcode": "130000",
  "city": [{
    "adcode": "130100",
    "name": "石家庄"
  }, {
    "adcode": "130200",
    "name": "唐山"
  }, {
    "adcode": "130300",
    "name": "秦皇岛"
  }, {
    "adcode": "130400",
    "name": "邯郸"
  }, {
    "adcode": "130500",
    "name": "邢台"
  }, {
    "adcode": "130600",
    "name": "保定"
  }, {
    "adcode": "130700",
    "name": "张家口"
  }, {
    "adcode": "130800",
    "name": "承德"
  }, {
    "adcode": "130900",
    "name": "沧州"
  }, {
    "adcode": "131000",
    "name": "廊坊"
  }, {
    "adcode": "131100",
    "name": "衡水"
  }]
}, {
  "name": "黑龙江",
  "adcode": "230000",
  "city": [{
    "adcode": "230100",
    "name": "哈尔滨"
  }, {
    "adcode": "230200",
    "name": "齐齐哈尔"
  }, {
    "adcode": "230300",
    "name": "鸡西"
  }, {
    "adcode": "230400",
    "name": "鹤岗"
  }, {
    "adcode": "230500",
    "name": "双鸭山"
  }, {
    "adcode": "230600",
    "name": "大庆"
  }, {
    "adcode": "230700",
    "name": "伊春"
  }, {
    "adcode": "230800",
    "name": "佳木斯"
  }, {
    "adcode": "230900",
    "name": "七台河"
  }, {
    "adcode": "231000",
    "name": "牡丹江"
  }, {
    "adcode": "231100",
    "name": "黑河"
  }, {
    "adcode": "231200",
    "name": "绥化"
  }, {
    "adcode": "232700",
    "name": "大兴安岭"
  }]
}, {
  "name": "河南省",
  "adcode": "410000",
  "city": [{
    "adcode": "410100",
    "name": "郑州"
  }, {
    "adcode": "410200",
    "name": "开封"
  }, {
    "adcode": "410300",
    "name": "洛阳"
  }, {
    "adcode": "410400",
    "name": "平顶山"
  }, {
    "adcode": "410500",
    "name": "安阳"
  }, {
    "adcode": "410600",
    "name": "鹤壁"
  }, {
    "adcode": "410700",
    "name": "新乡"
  }, {
    "adcode": "410800",
    "name": "焦作"
  }, {
    "adcode": "410900",
    "name": "濮阳"
  }, {
    "adcode": "411000",
    "name": "许昌市"
  }, {
    "adcode": "411100",
    "name": "漯河"
  }, {
    "adcode": "411200",
    "name": "三门峡"
  }, {
    "adcode": "411300",
    "name": "南阳"
  }, {
    "adcode": "411400",
    "name": "商丘"
  }, {
    "adcode": "411500",
    "name": "信阳"
  }, {
    "adcode": "411600",
    "name": "周口"
  }, {
    "adcode": "411700",
    "name": "驻马店"
  }, {
    "adcode": "419001",
    "name": "济源"
  }]
}, {
  "name": "湖北",
  "adcode": "420000",
  "city": [{
    "adcode": "420100",
    "name": "武汉"
  }, {
    "adcode": "420200",
    "name": "黄石"
  }, {
    "adcode": "420300",
    "name": "十堰"
  }, {
    "adcode": "420500",
    "name": "宜昌"
  }, {
    "adcode": "420600",
    "name": "襄阳"
  }, {
    "adcode": "420700",
    "name": "鄂州"
  }, {
    "adcode": "420800",
    "name": "荆门"
  }, {
    "adcode": "420900",
    "name": "孝感"
  }, {
    "adcode": "421000",
    "name": "荆州"
  }, {
    "adcode": "421100",
    "name": "黄冈"
  }, {
    "adcode": "421200",
    "name": "咸宁"
  }, {
    "adcode": "421300",
    "name": "随州"
  }, {
    "adcode": "422800",
    "name": "恩施土家族苗族自治州"
  }, {
    "adcode": "429004",
    "name": "仙桃"
  }, {
    "adcode": "429005",
    "name": "潜江"
  }, {
    "adcode": "429006",
    "name": "天门"
  }, {
    "adcode": "429021",
    "name": "神农架林区"
  }]
}, {
  "name": "湖南",
  "adcode": "430000",
  "city": [{
    "adcode": "430100",
    "name": "长沙"
  }, {
    "adcode": "430200",
    "name": "株洲"
  }, {
    "adcode": "430300",
    "name": "湘潭"
  }, {
    "adcode": "430400",
    "name": "衡阳"
  }, {
    "adcode": "430500",
    "name": "邵阳"
  }, {
    "adcode": "430600",
    "name": "岳阳"
  }, {
    "adcode": "430700",
    "name": "常德"
  }, {
    "adcode": "430800",
    "name": "张家界"
  }, {
    "adcode": "430900",
    "name": "益阳"
  }, {
    "adcode": "431000",
    "name": "郴州"
  }, {
    "adcode": "431100",
    "name": "永州"
  }, {
    "adcode": "431200",
    "name": "怀化"
  }, {
    "adcode": "431300",
    "name": "娄底"
  }, {
    "adcode": "433100",
    "name": "湘西土家族苗族自治州"
  }]
}, {
  "name": "海南",
  "adcode": "460000",
  "city": [{
    "adcode": "460100",
    "name": "海口"
  }, {
    "adcode": "460200",
    "name": "三亚"
  }, {
    "adcode": "460300",
    "name": "三沙"
  }, {
    "adcode": "460400",
    "name": "儋州"
  }]
}, {
  "name": "吉林",
  "adcode": "220000",
  "city": [{
    "adcode": "220100",
    "name": "长春"
  }, {
    "adcode": "220200",
    "name": "吉林"
  }, {
    "adcode": "220300",
    "name": "四平"
  }, {
    "adcode": "220400",
    "name": "辽源"
  }, {
    "adcode": "220500",
    "name": "通化"
  }, {
    "adcode": "220600",
    "name": "白山"
  }, {
    "adcode": "220700",
    "name": "松原"
  }, {
    "adcode": "220800",
    "name": "白城"
  }, {
    "adcode": "222400",
    "name": "延边朝鲜族自治州"
  }]
}, {
  "name": "江苏",
  "adcode": "320000",
  "city": [{
    "adcode": "320100",
    "name": "南京"
  }, {
    "adcode": "320200",
    "name": "无锡"
  }, {
    "adcode": "320300",
    "name": "徐州"
  }, {
    "adcode": "320400",
    "name": "常州"
  }, {
    "adcode": "320500",
    "name": "苏州"
  }, {
    "adcode": "320600",
    "name": "南通"
  }, {
    "adcode": "320700",
    "name": "连云港"
  }, {
    "adcode": "320800",
    "name": "淮安"
  }, {
    "adcode": "320900",
    "name": "盐城"
  }, {
    "adcode": "321000",
    "name": "扬州"
  }, {
    "adcode": "321100",
    "name": "镇江"
  }, {
    "adcode": "321200",
    "name": "泰州"
  }, {
    "adcode": "321300",
    "name": "宿迁"
  }]
}, {
  "name": "江西",
  "adcode": "360000",
  "city": [{
    "adcode": "360100",
    "name": "南昌"
  }, {
    "adcode": "360200",
    "name": "景德镇"
  }, {
    "adcode": "360300",
    "name": "萍乡"
  }, {
    "adcode": "360400",
    "name": "九江"
  }, {
    "adcode": "360500",
    "name": "新余"
  }, {
    "adcode": "360600",
    "name": "鹰潭"
  }, {
    "adcode": "360700",
    "name": "赣州"
  }, {
    "adcode": "360800",
    "name": "吉安"
  }, {
    "adcode": "360900",
    "name": "宜春"
  }, {
    "adcode": "361000",
    "name": "抚州"
  }, {
    "adcode": "361100",
    "name": "上饶"
  }]
}, {
  "name": "辽宁",
  "adcode": "210000",
  "city": [{
    "adcode": "210100",
    "name": "沈阳"
  }, {
    "adcode": "210200",
    "name": "大连"
  }, {
    "adcode": "210300",
    "name": "鞍山"
  }, {
    "adcode": "210400",
    "name": "抚顺"
  }, {
    "adcode": "210500",
    "name": "本溪"
  }, {
    "adcode": "210600",
    "name": "丹东"
  }, {
    "adcode": "210700",
    "name": "锦州"
  }, {
    "adcode": "210800",
    "name": "营口"
  }, {
    "adcode": "210900",
    "name": "阜新"
  }, {
    "adcode": "211000",
    "name": "辽阳"
  }, {
    "adcode": "211100",
    "name": "盘锦"
  }, {
    "adcode": "211200",
    "name": "铁岭"
  }, {
    "adcode": "211300",
    "name": "朝阳"
  }, {
    "adcode": "211400",
    "name": "葫芦岛"
  }]
}, {
  "name": "内蒙古",
  "adcode": "150000",
  "city": [{
    "adcode": "150100",
    "name": "呼和浩特"
  }, {
    "adcode": "150200",
    "name": "包头"
  }, {
    "adcode": "150300",
    "name": "乌海"
  }, {
    "adcode": "150400",
    "name": "赤峰"
  }, {
    "adcode": "150500",
    "name": "通辽"
  }, {
    "adcode": "150600",
    "name": "鄂尔多斯"
  }, {
    "adcode": "150700",
    "name": "呼伦贝尔"
  }, {
    "adcode": "150800",
    "name": "巴彦淖尔"
  }, {
    "adcode": "150900",
    "name": "乌兰察布"
  }, {
    "adcode": "152200",
    "name": "兴安盟"
  }, {
    "adcode": "152500",
    "name": "锡林郭勒盟"
  }, {
    "adcode": "152900",
    "name": "阿拉善盟"
  }]
}, {
  "name": "宁夏",
  "adcode": "640000",
  "city": [{
    "adcode": "640100",
    "name": "银川"
  }, {
    "adcode": "640200",
    "name": "石嘴山"
  }, {
    "adcode": "640300",
    "name": "吴忠"
  }, {
    "adcode": "640400",
    "name": "固原"
  }, {
    "adcode": "640500",
    "name": "中卫"
  }]
}, {
  "name": "青海",
  "adcode": "630000",
  "city": [{
    "adcode": "630100",
    "name": "西宁"
  }, {
    "adcode": "630200",
    "name": "海东"
  }, {
    "adcode": "632200",
    "name": "海北藏族自治州"
  }, {
    "adcode": "632300",
    "name": "黄南藏族自治州"
  }, {
    "adcode": "632500",
    "name": "海南藏族自治州"
  }, {
    "adcode": "632600",
    "name": "果洛藏族自治州"
  }, {
    "adcode": "632700",
    "name": "玉树藏族自治州"
  }, {
    "adcode": "632800",
    "name": "海西蒙古族藏族自治州"
  }]
}, {
  "name": "山西",
  "adcode": "140000",
  "city": [{
    "adcode": "140100",
    "name": "太原"
  }, {
    "adcode": "140200",
    "name": "大同"
  }, {
    "adcode": "140300",
    "name": "阳泉"
  }, {
    "adcode": "140400",
    "name": "长治"
  }, {
    "adcode": "140500",
    "name": "晋城"
  }, {
    "adcode": "140600",
    "name": "朔州"
  }, {
    "adcode": "140700",
    "name": "晋中"
  }, {
    "adcode": "140800",
    "name": "运城"
  }, {
    "adcode": "140900",
    "name": "忻州"
  }, {
    "adcode": "141000",
    "name": "临汾"
  }, {
    "adcode": "141100",
    "name": "吕梁"
  }]
}, {
  "name": "山东",
  "adcode": "370000",
  "city": [{
    "adcode": "370100",
    "name": "济南"
  }, {
    "adcode": "370200",
    "name": "青岛"
  }, {
    "adcode": "370300",
    "name": "淄博"
  }, {
    "adcode": "370400",
    "name": "枣庄"
  }, {
    "adcode": "370500",
    "name": "东营"
  }, {
    "adcode": "370600",
    "name": "烟台"
  }, {
    "adcode": "370700",
    "name": "潍坊"
  }, {
    "adcode": "370800",
    "name": "济宁"
  }, {
    "adcode": "370900",
    "name": "泰安"
  }, {
    "adcode": "371000",
    "name": "威海"
  }, {
    "adcode": "371100",
    "name": "日照"
  }, {
    "adcode": "371200",
    "name": "莱芜"
  }, {
    "adcode": "371300",
    "name": "临沂"
  }, {
    "adcode": "371400",
    "name": "德州"
  }, {
    "adcode": "371500",
    "name": "聊城"
  }, {
    "adcode": "371600",
    "name": "滨州"
  }, {
    "adcode": "371700",
    "name": "菏泽"
  }]
}, {
  "name": "四川",
  "adcode": "510000",
  "city": [{
    "adcode": "510100",
    "name": "成都"
  }, {
    "adcode": "510300",
    "name": "自贡"
  }, {
    "adcode": "510400",
    "name": "攀枝花"
  }, {
    "adcode": "510500",
    "name": "泸州"
  }, {
    "adcode": "510600",
    "name": "德阳"
  }, {
    "adcode": "510700",
    "name": "绵阳"
  }, {
    "adcode": "510800",
    "name": "广元"
  }, {
    "adcode": "510900",
    "name": "遂宁"
  }, {
    "adcode": "511000",
    "name": "内江"
  }, {
    "adcode": "511100",
    "name": "乐山"
  }, {
    "adcode": "511300",
    "name": "南充"
  }, {
    "adcode": "511400",
    "name": "眉山"
  }, {
    "adcode": "511500",
    "name": "宜宾"
  }, {
    "adcode": "511600",
    "name": "广安"
  }, {
    "adcode": "511700",
    "name": "达州"
  }, {
    "adcode": "511800",
    "name": "雅安"
  }, {
    "adcode": "511900",
    "name": "巴中"
  }, {
    "adcode": "512000",
    "name": "资阳"
  }, {
    "adcode": "513200",
    "name": "阿坝藏族羌族自治州"
  }, {
    "adcode": "513300",
    "name": "甘孜藏族自治州"
  }, {
    "adcode": "513400",
    "name": "凉山彝族自治州"
  }]
}, {
  "name": "陕西",
  "adcode": "610000",
  "city": [{
    "adcode": "610100",
    "name": "西安"
  }, {
    "adcode": "610200",
    "name": "铜川"
  }, {
    "adcode": "610300",
    "name": "宝鸡"
  }, {
    "adcode": "610400",
    "name": "咸阳"
  }, {
    "adcode": "610500",
    "name": "渭南"
  }, {
    "adcode": "610600",
    "name": "延安"
  }, {
    "adcode": "610700",
    "name": "汉中"
  }, {
    "adcode": "610800",
    "name": "榆林"
  }, {
    "adcode": "610900",
    "name": "安康"
  }, {
    "adcode": "611000",
    "name": "商洛"
  }]
}, {
  "name": "西藏",
  "adcode": "540000",
  "city": [{
    "adcode": "540100",
    "name": "拉萨"
  }, {
    "adcode": "540200",
    "name": "日喀则"
  }, {
    "adcode": "540300",
    "name": "昌都"
  }, {
    "adcode": "540400",
    "name": "林芝"
  }, {
    "adcode": "540500",
    "name": "山南"
  }, {
    "adcode": "540600",
    "name": "那曲"
  }, {
    "adcode": "542500",
    "name": "阿里"
  }]
}, {
  "name": "新疆",
  "adcode": "650000",
  "city": [{
    "adcode": "650100",
    "name": "乌鲁木齐"
  }, {
    "adcode": "650200",
    "name": "克拉玛依"
  }, {
    "adcode": "650400",
    "name": "吐鲁番"
  }, {
    "adcode": "650500",
    "name": "哈密"
  }, {
    "adcode": "652300",
    "name": "昌吉回族自治州"
  }, {
    "adcode": "652700",
    "name": "博尔塔拉蒙古自治州"
  }, {
    "adcode": "652800",
    "name": "巴音郭楞蒙古自治州"
  }, {
    "adcode": "652900",
    "name": "阿克苏"
  }, {
    "adcode": "653000",
    "name": "克孜勒苏柯尔克孜自治州"
  }, {
    "adcode": "653100",
    "name": "喀什"
  }, {
    "adcode": "653200",
    "name": "和田"
  }, {
    "adcode": "654000",
    "name": "伊犁哈萨克自治州"
  }, {
    "adcode": "654200",
    "name": "塔城"
  }, {
    "adcode": "654300",
    "name": "阿勒泰"
  }, {
    "adcode": "659001",
    "name": "石河子"
  }, {
    "adcode": "659002",
    "name": "阿拉尔"
  }, {
    "adcode": "659003",
    "name": "图木舒克"
  }, {
    "adcode": "659004",
    "name": "五家渠"
  }]
}, {
  "name": "云南",
  "adcode": "530000",
  "city": [{
    "adcode": "530100",
    "name": "昆明"
  }, {
    "adcode": "530300",
    "name": "曲靖"
  }, {
    "adcode": "530400",
    "name": "玉溪"
  }, {
    "adcode": "530500",
    "name": "保山"
  }, {
    "adcode": "530600",
    "name": "昭通"
  }, {
    "adcode": "530700",
    "name": "丽江"
  }, {
    "adcode": "530800",
    "name": "普洱"
  }, {
    "adcode": "530900",
    "name": "临沧"
  }, {
    "adcode": "532300",
    "name": "楚雄彝族自治州"
  }, {
    "adcode": "532500",
    "name": "红河哈尼族彝族自治州"
  }, {
    "adcode": "532600",
    "name": "文山壮族苗族自治州"
  }, {
    "adcode": "532800",
    "name": "西双版纳傣族自治州"
  }, {
    "adcode": "532900",
    "name": "大理白族自治州"
  }, {
    "adcode": "533100",
    "name": "德宏傣族景颇族自治州"
  }, {
    "adcode": "533300",
    "name": "怒江傈僳族自治州"
  }, {
    "adcode": "533400",
    "name": "迪庆藏族自治州"
  }]
}, {
  "name": "浙江",
  "adcode": "330000",
  "city": [{
    "adcode": "330100",
    "name": "杭州"
  }, {
    "adcode": "330200",
    "name": "宁波"
  }, {
    "adcode": "330300",
    "name": "温州"
  }, {
    "adcode": "330400",
    "name": "嘉兴"
  }, {
    "adcode": "330500",
    "name": "湖州"
  }, {
    "adcode": "330600",
    "name": "绍兴"
  }, {
    "adcode": "330700",
    "name": "金华"
  }, {
    "adcode": "330800",
    "name": "衢州"
  }, {
    "adcode": "330900",
    "name": "舟山"
  }, {
    "adcode": "331000",
    "name": "台州"
  }, {
    "adcode": "331100",
    "name": "丽水"
  }]
}, {
  "adcode": "310000",
  "name": "上海",
  "city": [{
    "adcode": "310100",
    "name": "市辖区",
  },
    {
      "adcode": "310101",
      "name": "黄浦区",
    },
    {
      "adcode": "310104",
      "name": "徐汇区",
    },
    {
      "adcode": "310105",
      "name": "长宁区",
    },
    {
      "adcode": "310106",
      "name": "静安区",
    },
    {
      "adcode": "310107",
      "name": "普陀区",
    },
    {
      "adcode": "310108",
      "name": "闸北区",
    },
    {
      "adcode": "310109",
      "name": "虹口区",
    },
    {
      "adcode": "310110",
      "name": "杨浦区",
    },
    {
      "adcode": "310112",
      "name": "闵行区",
    },
    {
      "adcode": "310113",
      "name": "宝山区",
    },
    {
      "adcode": "310114",
      "name": "嘉定区",
    },
    {
      "adcode": "310115",
      "name": "浦东新区",
    },
    {
      "adcode": "310116",
      "name": "金山区",
    },
    {
      "adcode": "310117",
      "name": "松江区",
    },
    {
      "adcode": "310118",
      "name": "青浦区",
    },
    {
      "adcode": "310120",
      "name": "奉贤区",
    },
    {
      "adcode": "310230",
      "name": "崇明县",
    }
  ]
}, {
  "name": "重庆",
  "adcode": "500000",
  "city": [{
    "adcode": "500100",
    "name": "市辖区",
  },
    {
      "adcode": "500101",
      "name": "万州区",
    },
    {
      "adcode": "500102",
      "name": "涪陵区",
    },
    {
      "adcode": "500103",
      "name": "渝中区",
    },
    {
      "adcode": "500104",
      "name": "大渡口区",
    },
    {
      "adcode": "500105",
      "name": "江北区",
    },
    {
      "adcode": "500106",
      "name": "沙坪坝区",
    },
    {
      "adcode": "500107",
      "name": "九龙坡区",
    },
    {
      "adcode": "500108",
      "name": "南岸区",
    },
    {
      "adcode": "500109",
      "name": "北碚区",
    },
    {
      "adcode": "500110",
      "name": "綦江区",
    },
    {
      "adcode": "500111",
      "name": "大足区",
    },
    {
      "adcode": "500112",
      "name": "渝北区",
    },
    {
      "adcode": "500113",
      "name": "巴南区",
    },
    {
      "adcode": "500114",
      "name": "黔江区",
    },
    {
      "adcode": "500115",
      "name": "长寿区",
    },
    {
      "adcode": "500222",
      "name": "綦江区",
    },
    {
      "adcode": "500223",
      "name": "潼南县",
    },
    {
      "adcode": "500224",
      "name": "铜梁县",
    },
    {
      "adcode": "500225",
      "name": "大足区",
    },
    {
      "adcode": "500226",
      "name": "荣昌县",
    },
    {
      "adcode": "500227",
      "name": "璧山县",
    },
    {
      "adcode": "500228",
      "name": "梁平县",
    },
    {
      "adcode": "500229",
      "name": "城口县",
    },
    {
      "adcode": "500230",
      "name": "丰都县",
    },
    {
      "adcode": "500231",
      "name": "垫江县",
    },
    {
      "adcode": "500232",
      "name": "武隆县",
    },
    {
      "adcode": "500233",
      "name": "忠县",
    },
    {
      "adcode": "500234",
      "name": "开县",
    },
    {
      "adcode": "500235",
      "name": "云阳县",
    },
    {
      "adcode": "500236",
      "name": "奉节县",
    },
    {
      "adcode": "500237",
      "name": "巫山县",
    },
    {
      "adcode": "500238",
      "name": "巫溪县",
    },
    {
      "adcode": "500240",
      "name": "石柱土家族自治县",
    },
    {
      "adcode": "500241",
      "name": "秀山土家族苗族自治县",

    },
    {
      "adcode": "500242",
      "name": "酉阳土家族苗族自治县",
    },
    {
      "adcode": "500243",
      "name": "彭水苗族土家族自治县",
    },
    {
      "adcode": "500381",
      "name": "江津区",
    },
    {
      "adcode": "500382",
      "name": "合川区",
    },
    {
      "adcode": "500383",
      "name": "永川区",
    },
    {
      "adcode": "500384",
      "name": "南川区",
    }]
}, {
  "name": "北京",
  "adcode": "110000",
  "city": [{
    "adcode": "110101",
    "name": "东城区",
  },
    {
      "adcode": "110102",
      "name": "西城区",
    },
    {
      "adcode": "110105",
      "name": "朝阳区",
    },
    {
      "adcode": "110106",
      "name": "丰台区",
    },
    {
      "adcode": "110107",
      "name": "石景山区",
    },
    {
      "adcode": "110108",
      "name": "海淀区",
    },
    {
      "adcode": "110109",
      "name": "门头沟区",
    },
    {
      "adcode": "110111",
      "name": "房山区",
    },
    {
      "adcode": "110112",
      "name": "通州区",
    },
    {
      "adcode": "110113",
      "name": "顺义区",
    },
    {
      "adcode": "110114",
      "name": "昌平区",
    },
    {
      "adcode": "110115",
      "name": "大兴区",
    },
    {
      "adcode": "110116",
      "name": "怀柔区",
    },
    {
      "adcode": "110117",
      "name": "平谷区",
    },
    {
      "adcode": "110118",
      "name": "亦庄开发区",
    },
    {
      "adcode": "110228",
      "name": "密云县",
    },
    {
      "adcode": "110229",
      "name": "延庆县",
    }]
}, {
  "name": "天津",
  "adcode": "120000",
  "city": [{
    "adcode": "120101",
    "name": "和平区",
  },
    {
      "adcode": "120102",
      "name": "河东区",
    },
    {
      "adcode": "120103",
      "name": "河西区",
    },
    {
      "adcode": "120104",
      "name": "南开区",
    },
    {
      "adcode": "120105",
      "name": "河北区",
    },
    {
      "adcode": "120106",
      "name": "红桥区",
    },
    {
      "adcode": "120107",
      "name": "塘沽区",
    },
    {
      "adcode": "120108",
      "name": "汉沽区",
    },
    {
      "adcode": "120109",
      "name": "大港区",
    },
    {
      "adcode": "120110",
      "name": "东丽区",
    },
    {
      "adcode": "120111",
      "name": "西青区",
    },
    {
      "adcode": "120112",
      "name": "津南区",
    },
    {
      "adcode": "120113",
      "name": "北辰区",
    },
    {
      "adcode": "120114",
      "name": "武清区",
    },
    {
      "adcode": "120115",
      "name": "宝坻区",
    },
    {
      "adcode": "120221",
      "name": "宁河县",
    },
    {
      "adcode": "120223",
      "name": "静海县",
    },
    {
      "adcode": "120225",
      "name": "蓟县",
    }]
}, {
  "name": "台湾",
  "adcode": "710000",
  "city": [{
    "adcode": "719002",
    "name": "台北"
  }, {
    "adcode": "719019",
    "name": "高雄"
  }, {
    "adcode": "719017",
    "name": "台中"
  }, {
    "adcode": "719016",
    "name": "台南"
  }, {
    "adcode": "719010",
    "name": "新北"
  }, {
    "adcode": "719011",
    "name": "基隆"
  }, {
    "adcode": "719006",
    "name": "新竹"
  }, {
    "adcode": "719004",
    "name": "嘉义"
  }]
}, {
  "name": "香港特别行政区",
  "adcode": "810000",
}, {
  "name": "澳门特别行政区",
  "adcode": "820000",
}
]
