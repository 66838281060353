import Vue from 'vue'
import App from './App.vue'
import router from './router'
Vue.config.productionTip = false

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

// import axios from 'axios'
// var instance = axios.create({
//   baseURL: 'http://127.0.0.1:8080/',
//   // baseURL: '/api',
//   timeout: 10000 // 请求超时时间
// })
//
// Vue.prototype.$axios = instance;

new Vue({
  render: h => h(App),
  router,
}).$mount('#app')

router.beforeEach((to,from,next)=>{
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})
