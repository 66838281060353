<template>
  <div class="not-found">
    <img src="../assets/404.png" alt="404 Not Found Image">
    <p>抱歉，页面未找到</p>
    <!-- 可以添加返回首页的按钮或其他导航提示 -->
  </div>
</template>

<script>
export default {
  name: "404"
}
</script>

<style scoped>
.not-found {
  text-align: center;
  padding: 20px;
  /* 添加自定义样式以美化页面 */
}
</style>
