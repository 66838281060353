import Vue from 'vue'
import Router from 'vue-router'
import Index from '@/pages/index.vue'
import Result from '@/pages/result.vue'
import NotFound  from '@/pages/404.vue'
import Salesman  from '@/pages/salesman.vue'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/index/:code',
      name: 'index',
      component: Index,
      meta: {
        title: '车享贷',
      }
    },
    {
      path: '/s/:code',
      name: 'salesman',
      component: Salesman,
      meta: {
        title: '车享贷',
      }
    },
    {
      path: '/result',
      name: 'result',
      component: Result,
      meta: {
        title: '车享贷',
      }
    },
    {
      path: '/404',
      name: '404',
      component: NotFound,
      meta: {
        title: '车享贷',
      }
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
})
