<template>
  <div style="width: 100vw;height: 100vh;" class="box">

    <img src="../assets/s2.png" @click="goWechat"  style="margin-bottom: 5vh;width: 80%;"/>
  </div>
</template>

<script>
import {saleManInfo} from '@/api/index.js'
import {Toast} from "vant";
export default {
  name: "index",
  data(){
    return{
      jobCode:"",
      wechatUrl:"",
      loading:true,
    }
  },
  created() {
    this.jobCode = this.$route.params.code;
    console.log("当前业务员编号:",this.jobCode);
    this.getSaleManInfo()
  },
  methods:{
    getSaleManInfo(){
      saleManInfo(this.jobCode).then(response=>{
        if(response.data.code===200){
          // Toast.success('发送成功');
          if(response.data.data&&response.data.data.wechatUrl){
            // window.location.href=response.data.data.wechatUrl;
            this.wechatUrl = response.data.data.wechatUrl;
          }
        }else {
          Toast.fail('请求失败:'+response.data.msg);
        }
      }).catch(err=>{
        console.log("请求失败异常:",err)
      })
    },
    goWechat(){
      console.log("点击了",this.wechatUrl);
      if(this.wechatUrl){
        window.location.href=this.wechatUrl;
      }else {
        window.location.href= "https://work.weixin.qq.com/ca/cawcde39b19220c6d6";
      }
    }
  }
}
</script>
<style scoped>
  .box{
    background-image:url("../assets/s1.png"),linear-gradient(to bottom, #FEAD8D, #FE9267);
    background-repeat: no-repeat;
    background-size: 100vw auto;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
</style>