<template>
  <div style="width: 100vw;height: auto;background: #fff;">
    <div style="width: 100%;display: flex;align-items: center;flex-direction: column">
      <img src="../assets/x8.png" style="width: 100%;height: auto;">
      <img src="../assets/x9.png" style="width: 86%;height: auto;margin-top: 15px;">
      <div class="div_row" style="justify-content: center;font-size: 12px;color: #5C4545;padding-bottom: 40px;">
        <span>如有任何疑问 </span>
        <span style="color: #FF2E0C">请联系官方客服</span>
      </div>
    </div>

    <!-- 弹窗组件 -->
    <van-dialog
        v-model="showDialog"
        :show-confirm-button="false"
    >
     <div class="div_col_center" style="text-align: center">
       <div style="color: #645d5d;font-size: 18px;padding-bottom: 12px;border-bottom: 1px solid #DDE0E6;width: 100%">专属车贷咨询热线</div>
       <div style="color: #4E97FF;font-size: 18px;padding-bottom: 12px;padding-top:12px;border-bottom: 1px solid #DDE0E6;width: 100%">4001818973</div>

       <div style="color: #4E97FF;font-size: 18px;padding-bottom: 12px;padding-top:12px;border-bottom: 1px solid #DDE0E6;width: 100%">
         <a href="tel:4001818973">呼叫</a>
       </div>
       <div style="color: #645d5d;font-size: 18px;padding-top:12px;width: 100%" @click="showDialog=false">取消</div>
     </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  name: "result",
  data(){
    return{
      showDialog:true,
    }
  },
  methods:{
    onConfirm(){
      this.showDialog = false;
    },
    onCancel(){
      this.showDialog = false;
    },
    async copyNumber() {
      this.showDialog = false;
      try {
        await navigator.clipboard.writeText("4001818973");
        console.log('号码已复制到剪贴板');
      } catch (err) {
        console.error('复制到剪贴板失败', err);
      }
    }
  }
}
</script>

<style scoped>
  a{
    color: #4E97FF;
  }
  .box{
    width: 100vw;
    height: 100vh;
    background-image:url("../assets/result_bg.png");
    background-repeat: no-repeat;
    background-size: 100vw auto;
  }
  .div_col{
    display: flex;
    flex-direction: column;
  }
  .div_col_center{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 20px;
  }
  .div_row{
    display: flex;
    flex-direction: row;
  }
  .main_box{
    width: 90vw;
    margin-left: 5vw;
    display: flex;
    flex-direction: column;
    padding-top: 46px;
  }
  .txt_title{
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    color: #000000;
    line-height: 31px;
  }
  .txt_content{
    background: #ffffff;
    box-shadow: 0px 4px 5px 0px rgba(237,175,147,0.06);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #FFF2EC;
    padding: 10px 10px;
    margin-top: 16px;
    font-size: 14px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #666;
    line-height: 25px;
    text-align: center;
  }
  .title2{
    font-size: 16px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #000000;
    line-height: 25px;
    margin-left: 6px;
  }
  .item_box{
    background: #FFFFFF;
    box-shadow: 0px 4px 5px 0px rgba(237,175,147,0.06);
    border-radius: 8px 8px 8px 8px;
    opacity: 1;
    border: 1px solid #FFF2EC;
    font-size: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #7A736F;
    line-height: 22px;
    padding: 10px;
    margin-top: 6px;
  }
  .text3{
    font-size: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #CFCFCF;
  }
</style>
