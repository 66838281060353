import CryptoJS from 'crypto-js'
import Base64 from 'crypto-js/enc-base64'
import md5 from 'crypto-js/md5'

// 加盐key值
const FIXED_KEY = 'DYXvxWfyg8jbLQhO'

// 加密
export const aesEncode = (devid, text) => {
    const key = CryptoJS.enc.Utf8.parse(md5(FIXED_KEY + devid).toString())
    // console.log(md5(FIXED_KEY + devid), 'aes-md5密文key')
    const src = CryptoJS.enc.Utf8.parse(text)
    const result = CryptoJS.AES.encrypt(src, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
    }).toString()
    // console.log(result, 'aes-加密明文')
    // console.log(Base64.encode(result), 'aes-加密base64结果')
    return result
}
